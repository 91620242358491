














































import Vue from 'vue'
import Card from '@/components/Card.vue'
import textParts from '@/assets/texts/statics.json'
import Start from '@/components/Start.vue'
import router from '@/router'

export default Vue.extend({
  name: 'Home',
  components: {
    Card,
    Start
  },
  data: () => ({
    textParts: textParts,
    innerWidth: window.innerWidth
  }),
  methods: {
    handleResize (): void {
      this.innerWidth = window.innerWidth
    },
    navigateTo (routeName: string): void {
      if (router.currentRoute.name !== routeName) {
        router.push({ name: routeName })
      }
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.innerWidth <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    group () {
      this.innerWidth = window.innerWidth
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  }
})

