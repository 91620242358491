












































































































































































import Vue from 'vue'
import router from './router'
// import Header from './components/Header.vue'
export default Vue.extend({
  name: 'App',

  components: {
    // Home
    // Header
  },

  data: () => ({
    drawer: false,
    group: null
  }),
  methods: {
    navigateTo (routeName: string): void {
      if (router.currentRoute.name !== routeName) {
        router.push({ name: routeName })
      }
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  }
})
