




















import Vue from 'vue'

export default Vue.extend({
  name: 'Card',
  props: {
    imgUrl: String,
    text!: String,
    title!: String,
    right: Boolean
  },
  data () {
    return {
      hiddenStyle: {
        visibility: 'hidden'
      }
    }
  },
  methods: {
    validateImgUrl () {
      if (!this.imgUrl || this.imgUrl.length === 0) {
      }
    }
  }

})

